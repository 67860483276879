:root {
  --text-black: #2a2a2a;
  --border-black: #1a1a1a;
}

@font-face {
  font-family: 'Raleway', sans-serif;
}
@font-face {
  font-family: 'Open Sans', sans-serif;
}
@font-face {
  font-family: 'Libre Baskerville', serif;
}
@font-face {
  font-family: 'Poppins', sans-serif;
}

.raleway {
  font-family: 'Raleway', sans-serif;
}

.sans {
  font-family: 'Open Sans', sans-serif;
}

.libre {
  font-family: 'Libre Baskerville', serif;
}

.poppins {
  font-family: 'Poppins', sans-serif;
}

.margin-zero-auto {
  margin: 0 auto;
}

.resume-highest-parent {
  font-family: 'Libre Baskerville', serif;
  font-size: 14px;
}

.resume-picture-plus-info {
  margin-top: 50px;
}

.resume-picture {
  border-radius: 50%;
}

.resume-social-media-parent {
  margin-top: 10px;
}

.resume-social-media-buttons {
  max-height: 30px;
  max-width: 30px;
  height: auto;
  width: auto;
  margin: 2px 2px 0;
}

.resume-highest-parent {
  width: auto;
  height: auto;
  min-width: 320px;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  margin: 15px auto 0;
}

.resume-personal-section {
  max-width: 300px;
  margin: 0 auto;
  display: block;
}

.resume-professional-section {
  max-width: 680px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding: 0 10px;
}

.resume-professional-child {
  padding: 3px 0;
}

.resume-title-bar {
  text-align: left;
  font-size: 22px;
  /*border-top: 1px solid black;*/
  /*border-bottom: 1px solid black;*/
  margin: 5px auto;
}

.resume-skills-parent {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}

.resume-about-me-parent {
  min-height: 120px;
  margin: 30px 0;
}

.resume-about-me {
  text-align: justify;
  line-height: 1.75;
  padding: 12px 0 24px;
}

.resume-jobs-parent {
  min-height:150px;
}

.resume-skills-highest-parent {
  min-height: 100px;
  margin-bottom: 50px;
}

.resume-skills-skill {
  width: 33%;
  min-width: 60px;
  height: 24px;
  background-color: #555;
  color: white;
  padding: 0 2px;
  margin: 2px auto;
  border-left: 1px solid white;
  border-right: 1px solid white;
  font-size: 22px;
}

.resume-skills-title-box {
  margin: 25px auto 5px;
}

.resume-job-experience {
  border: 1px solid #ccc;
  margin: 12px auto;
  background: #f2f2f2;
  padding: 16px 0;
}

.resume-job-dates-parent {
  display: flex;
  flex-wrap: wrap;
}

.resume-job-company-name {
  font-size: 24px;
}

.resume-job-position {
  font-size: 18px;
}

.resume-job-company-name,
.resume-job-position {
  height: auto;
  margin-bottom: 10px;
}

.resume-job-company-name,
.resume-job-position {
  width: auto;
  margin: 10px auto;
}

.resume-job-dates-parent {
  margin: 10px auto;
  font-size: 12px;
}

.resume-job-date {
  width: auto;
}

.resume-job-start-date {
  text-align: right;
  margin-left: auto;
}

.resume-job-end-date {
  text-align: left;
  margin-right: auto;
}

.hide {
  height: 0;
  width: 0;
  display: none;
}

/* Below 320px */
@media only screen and (max-width: 320px) {
  .resume-skills-parent {
    width: 320px;
  }

  .resume-professional-section {
    margin: 100px auto 0;
  }
}

/* Values in parenthesis must evaluate to true to be enabled. EG: (min-width: 320px) means screen must be 320px or more.
Queries must be included sequentially to overwrite values placed for smaller screens. Whichever query evaluates true last
will be the final values used. Values specified below the queries will overwrite the values specified in the queries. */

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
  .resume-skills-parent {
    width: 320px;
  }

  .resume-professional-section {
    margin: 50px auto 0;
    width: 100%;
  }
}

@media only screen and (min-width: 480px) {
  .resume-skills-parent {
    width: 460px;
  }
}

@media only screen and (min-width: 600px) {
  .resume-skills-parent {
    width: 586px;
  }

  .resume-job-experience-title-box,
  .resume-about-me-title-box {
    width: auto;
  }
}

@media only screen and (min-width: 980px) {
  /*.resume-professional-section {*/
    /*margin-top: 50px;*/
  /*}*/
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  .resume-title-bar {
    width: auto;
  }
}
